import Swiper from 'swiper';

jQuery(function ($) {
    var slider = new Swiper('.swiper-container', {
        loop: true,
        autoplay: 3000,
    });
    $('.swiper-button-prev').click(function () {
        slider.swipePrev();
    });

    $('.swiper-button-next').click(function () {
        slider.swipeNext();
    });

    $(document).on('click', 'img.zoom', function () {
        $(this).toggleClass('zoomed');
        $('.overlay').fadeToggle();
    });


    if ($(window).width() < 800) {

        $('ul.menu .menu-item-has-children a').click(function () {

            $(this).siblings('ul').slideToggle();

        });

        $('.menu_tel').click(function () {
            $('.menu').slideToggle();
        });
    }

});
